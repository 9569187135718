import React from 'react';
import './CommonContactBox.css';

const CommonContactBox = () => {
	return (
		
		<div className="home-common-wrap">
			<div className="section-7">
				<div className="contact-taglines">
					<div className="tagline">{'What can we do to assist you?'}</div>
					<div className="tagline-text">{'Our Travel Experts are here to assist you at any time.'}</div>
				</div>
				<div className="phone-number">
					<i className="fa-solid fa-phone"></i>
					<div className="p-number">
						<a href="tel:0431133327">{'0431 133 327'}</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CommonContactBox;
