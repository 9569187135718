import React from 'react';
import ContactForm from '../../ContactForm/ContactForm';
import TopicText from '../../TopicText/TopicText';
import './ContactUsSection2.css';

const ContactUsSection2 = () => {
	return (
		<div className="gq-sec-2">
			<div className="gq-sec-2__topic">
				<TopicText
					title="Send us your Enquiry"
					paragraph="The state of Malaysia, which belongs to the South East Asian region, is divided into two peninsulas by the South China Sea. It is a country of great biodiversity, human habitation, and pollution-free land with beautiful high mountain peaks. Also Malaysia is filled with theme parks, Historical places and many more attractive plac es to visit and delight your eyes."
				></TopicText>
			</div>
			<br />
			<div className="gq-sec-2__form-container">
				<ContactForm></ContactForm>
			</div>
		</div>
	);
};

export default ContactUsSection2;
