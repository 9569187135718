import React from 'react';
import './GetQuoteSection4.css';
import CommonContactBox from '../../../common/CommonContactBox/CommonContactBox';

const GetQuoteSection4 = () => {
	return (
		<div className="gq-sec-4">
			<CommonContactBox></CommonContactBox>
		</div>
	);
};
export default GetQuoteSection4;
