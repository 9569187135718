/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import './Navbar.css';
import Logo from '../../img/ISC-Logo.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
	const ref = useRef(null);
	const onClickHamburger = () => {
		const menu = ref.current; // corresponding DOM node
		menu.className = menu.className === 'navbar-menu' ? 'navbar-menu is-active' : 'navbar-menu';
	};
	return (
		<div className="navbar-wrap">
			<nav
				className="navbar is-black is-fixed-top px-6 py-3 home-common-wrap"
				role="navigation"
				aria-label="main navigation"
			>
				{/* Logo */}
				<div className="navbar-brand">
					<Link className="navbar-item" to='/'>
						<img src={Logo} alt="Flight Master" className="logo-image my-1 mx-5" />
					</Link>
					<a className="navbar-burger" onClick={onClickHamburger}>
						<span></span>
						<span></span>
						<span></span>
					</a>
				</div>
				{/* Menu */}
				<div ref={ref} className="navbar-menu" id="nav-links">
					<div className="navbar-end">
						<div className="navbar-item px-4 py-2">
							<Link className="navbar-item px-4 py-2" to="/">HOME</Link>
						</div>
						<div className="navbar-item px-4 py-2">
							<Link className="navbar-item px-4 py-2" to="/get-quote">GET A QUOTE</Link>
						</div>
						<div className="navbar-item  py-2 contact-btn-wrap" >
							<Link to='/contact-us'>
								<button className="contact-btn">CONTACT US</button>
							</Link>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Navbar;
