import TopicText from '../../TopicText/TopicText';
import './Section7.css';

const Section7 = () => {
	return (
		<>
			<TopicText
				title="Flexibility and COVID-19"
				paragraph="COVID-19 may have changed the way we all travel, but it hasn't changed our dedication to our clients' and team members' health and safety. This is, as usual, our first priority.
				The COVID-19 Help Connector from Flight Master is your 'one-stop shop' for all you need to know about this new era of travel, whether you're planning a trip or you’re already on one."
			/>
		</>
	);
};

export default Section7;