import './Section5.css';
import CustomerCare from '../../../img/cutomer-care.png';

const Section5 = () => {
	return (
		<div className="home-common-wrap">
			<div className="common-section-containers section-5">
				<div className="slogan-container">
					<h1 className="tagline">{"Flight Master"}</h1>
					<h1 className="tagline sub-tagline">{"Your Best Travel Assistant"}</h1>
					<p className="slogan-text">
						{"Place your guidebook aside and join a tour conducted by local experts who will expose you to the city's secrets. It's entirely up to you whether you want to go for historical, cultural, culinary, romantic, or adventurous options; our flight itinerary includes international destinations to suit all those needs. What’s more, we have more destinations just waiting to be added, along with customisable fares. Keep an eye out for more information. For your satisfaction, we’re prepared to go above and beyond."}
					</p>
					<button className="section-5-btn section-btn">{'See More'}</button>
				</div>
				<div className="img-container">
					<img src={CustomerCare} alt="Your travel partner" />
				</div>
			</div>
		</div>
	);
};

export default Section5;
