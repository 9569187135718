import BaliMyna from '../../../img/bali-myna.png';
import './Section4.css';

const Section4 = () => {
	return (
		<div className="home-common-wrap">
			<div className="common-section-containers  section-4">
				<div className="img-container">
					<img src={BaliMyna} alt="Your travel partner" />
				</div>
				<div className="slogan-container">
					<h1 className="tagline mb-3">
						{'Shake Your Tail Feathers at Jurong Bird Park'}
					</h1>
					<h1 className="tagline sub-tagline">{'Singapore'}</h1>
					<p className="slogan-text">
						{'The Jurong Bird Park’s commitment to conservation and protection of its 3,500 feathered residents has won it a slew of awards and gained international renown. Some 400 species (many of them endangered) live here in diverse zones designed to replicate their natural environments. Immerse yourself in the rainforest of ‘African Treetops’, home to colorful starlings, turacos, and hornbills; or hang out with 600 feathered friends in the huge free-flight Waterfall Aviary.'}
					</p>
					<div className="btn-container">
						<button className="section-btn">{'See More'}</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Section4;
