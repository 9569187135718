import './DestinationPost.css';

const DestinationPost = (props) => {
	return (
		<div id={props.id} className="d-post-container">
			<div className="d-img-container">
				<img src={props.img} alt="Destination"/>
			</div>
			<div className="d-text-container">
				<div className="d-heading">{props.title}</div>
				<p className="d-paragraph">{props.paragraph}</p>
				<a className="d-post-link" href='/'>
					<span>Read More</span><i className="fa-solid fa-angle-right"></i>
				</a>
			</div>
		</div>
	);
};

export default DestinationPost;