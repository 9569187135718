import React from 'react';
import './ContactUsSection5.css';
import CommonContactBox from '../../../common/CommonContactBox/CommonContactBox';

const ContactUsSection5 = () => {
	return (
		<div className="gq-sec-5">
			<CommonContactBox></CommonContactBox>
		</div>
	);
};

export default ContactUsSection5;
