import React from 'react';
import './ContactUs.css';
import ContactUsSection1 from '../../components/ContactUsSection/ContactUsSection1/ContactUsSection1';
import ContactUsSection2 from '../../components/ContactUsSection/ContactUsSection2/ContactUsSection2';
import ContactUsSection3 from '../../components/ContactUsSection/ContactUsSection3/ContactUsSection3';
import ContactUsSection4 from '../../components/ContactUsSection/ContactUsSection4/ContactUsSection4';
import ContactUsSection5 from '../../components/ContactUsSection/ContactUsSection5/ContactUsSection5';
import ContactUsFooter from '../../common/Footer/ContactUsFooter/ContactUsFooter'
const ContactUs = () => {
	return (
		<div className="contact-us-main">
			<div className="cu-first-section">
				<ContactUsSection1></ContactUsSection1>
			</div>
			<div className="cu-second-section cu-section">
				<ContactUsSection2></ContactUsSection2>
			</div>
			<div className="cu-third-section cu-section">
				<ContactUsSection3></ContactUsSection3>
			</div>
			<div className="cu-fourth-section cu-section">
				<ContactUsSection4></ContactUsSection4>
			</div>
			<div className="cu-fifth-section cu-section">
				<ContactUsSection5></ContactUsSection5>
			</div>
			<ContactUsFooter />
		</div>
	);
};

export default ContactUs;
