/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './Popup.css';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

// import PassengerTypeInput from '../../GetQuoteSections/GetQuoteSection2/PassengerTypeInput';
// import PopupBottomInput from '../../Input/PopupBottomInput/PopupBottomInput';

import Logo from '../../../img/Logo.png';
import Icon1 from '../../../img/icons/popup-icon-1.png';
import Icon2 from '../../../img/icons/popup-icon-2.png';
import Icon3 from '../../../img/icons/popup-icon-3.png';
import Icon4 from '../../../img/icons/popup-icon-4.png';
import Icon5 from '../../../img/icons/popup-icon-5.png';

const Popup = ({ openPopup, closePopup, data }) => {
	const bottomData = [
		{
			id: 'i1',
			icon: { Icon1 },
			inputBorderText: 'Flying From',
			itemData: '',
			placeHolder: 'Gold Coast Airport',
		},
		{
			id: 'i2',
			icon: { Icon2 },
			inputBorderText: 'Flying To',
			itemData: '',
			placeHolder: 'Adelaide Airport',
		},
		{
			id: 'i3',
			icon: { Icon3 },
			inputBorderText: 'From',
			itemData: { type: 'date', value: '' },
			placeHolder: '',
		},
		{
			id: 'i4',
			icon: { Icon3 },
			inputBorderText: 'To',
			itemData: { type: 'date', value: '' },
			placeHolder: '',
		},
		{
			id: 'i5',
			icon: { Icon4 },
			inputBorderText: '',
			itemData: '',
			placeHolder: 'Name',
		},
		{
			id: 'i6',
			icon: { Icon5 },
			inputBorderText: '',
			itemData: { type: 'tel', maxLength: 10 },
			placeHolder: 'Phone No.',
		},
	];

	const [formdata, setFormdata] = useState({
		'trip-type': 'return',
		classes: 'economy',
		Adults: 0,
		Children: 0,
		Infants: 0,
		flyingFrom: bottomData[0].placeHolder,
		flyingTo: bottomData[1].placeHolder,
	});

	const onFormChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setFormdata((formdata) => ({ ...formdata, [name]: value }));
	};

	const submitEmail = (event) => {
		event.preventDefault();
		console.log(formdata)
		emailjs.send('service_ukz9c9m', 'template_s8pxaz6', formdata, '764tE797wwOEx9DU4').then(
			(result) => {
				Swal.fire({
				title: 'Success',
				text: ' Successfully Sent Your Request',
				icon: 'success',
				allowOutsideClick: false,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: 'OK',
				confirmButtonColor: '#E94E1B',
				cancelButtonText: 'Cancel',
				imageUrl: null,
				imageSize: null,
				timer: null,
				customClass: '',
				html: false,
				animation: true,
				allowEscapeKey: true,
				inputType: 'text',
				inputPlaceholder: '',
				inputValue: '',
				showLoaderOnConfirm: false	
			})
			},
			(error) => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...!',
					text: error.text,
				});
			}
		);
	};

	return (
		<div className="popup-container">
			<a className="popup-close-btn" onClick={closePopup}>
				<i className="fa-solid fa-xmark"></i>
			</a>
			<div className="popup-header">
				<img src={Logo} alt="Logo" />
				<div className="popup-header-h1-text">
					Check Avaiability of Flight to{' '}
					<span className="popup-header-h1-text popup-header-h1-text_destination">
						{data.title}
					</span>{' '}
				</div>
				<div className="popup-header-h2-text">
					One way from{' '}
					<span className="popup-header-h2-text popup-header-h2-text__price">
						{data.price}
					</span>{' '}
					, Deal number:{' '}
					<span className="popup-header-h2-text popup-header-h2-text__deal-number">
						123_456
					</span>
				</div>
			</div>
			<form className="popup__form" onSubmit={submitEmail} onChange={onFormChange}>
				<h1 className="form__tagline">
					Travel between 18 Jul 2022 - 23 Sep 2022 for this deal.
				</h1>
				<div className="trip-type__section popup__section">
					<div className="trip-type__radio-btns">
						<div className="trip-type__radio-btn trip-type__radio-btn">
							<input
								type="radio"
								id="return-radio-btn"
								name="trip-type"
								value="return"
								radioGroup="trip-type"
								defaultChecked
								style={{accentColor:"rgb(233,78,27)"}}
							/>
							<label className="triptype-btn__label" htmlFor="return-radio-btn">
								Return
							</label>
						</div>
						<div className="trip-type__radio-btn radio-btn-2">
							<input
								type="radio"
								id="oneway-radio-btn"
								name="trip-type"
								value="one-way"
								radioGroup="trip-type"
								style={{accentColor:"rgb(233,78,27)"}}
							/>
							<label className="triptype-btn__label" htmlFor="oneway-radio-btn">
								OneWay
							</label>
						</div>
					</div>
				</div>
				<div className="flight-class__section popup__section">
					<div>
						<h1 className="flight-class__header">Ticket Class</h1>
						<select
							className="flight-classes__selector"
							name="classes"
							id="flight-classes"
							defaultValue={'business'}
						>
							<option className="flight-classes__selector-option" value="business">
								Business Class
							</option>
							<option className="flight-classes__selector-option" value="economy">
								Economy Class
							</option>
						</select>
					</div>
				</div>
				<div className="passengers__section popup__section">
					<h1 className="passengers__header">Passengers</h1>
					<div className="popup-passengers__input-container">
						{''}
						<div className="popup-passenger-type__container">
							<h1 className="passenger-type__header">{'Adults'}</h1>
							<h3
								style={{ paddingBottom: '24px' }}
								className="passenger-age-group__sub-header"
							>
								{' '}
							</h3>
							<input
								className="passenger-type__input"
								type="number"
								id="points"
								name="Adults"
								defaultValue={0}
							/>
						</div>
						<div className="popup-passenger-type__container">
							<h1 className="passenger-type__header">{'Children'}</h1>
							<h3 className="passenger-age-group__sub-header">{'2-11 years'}</h3>
							<input
								className="passenger-type__input"
								type="number"
								id="points"
								name="Children"
								defaultValue={0}
							/>
						</div>
						<div className="popup-passenger-type__container">
							<h1 className="passenger-type__header">{'Infants'}</h1>
							<h3 className="passenger-age-group__sub-header">{'under 2 years'}</h3>
							<input
								className="passenger-type__input"
								type="number"
								id="points"
								name="Infants"
								defaultValue={0}
							/>
						</div>
					</div>
				</div>
				<div className="destination__input-container">
					{/* <PopupBottomInput
							inputId={bottomData[0].id}
							inputIcon={Icon1}
							borderText={bottomData[0].inputBorderText}
							data={bottomData[0].itemData}
							placeHolderText={bottomData[0].placeHolder}
							inputName={'flyingFrom'}
							readonly={true}
						/> */}
					<div className="popup-input__container">
						<img className="popup-input__img" src={Icon1} alt="Icon" />
						<div className="popup-input__wrap">
							<label className="popup-input__border-text" htmlFor={bottomData[0].id}>
								{bottomData[0].inputBorderText}
							</label>
							<input
								className="popup-input__input"
								id={bottomData[0].id}
								type={data.type}
								placeholder={bottomData[0].placeHolder}
								maxLength={bottomData[0].itemData.type}
								name={'flyingFrom'}
								readOnly={false}
								required={true}
							/>
						</div>
					</div>{' '}
					<div className="popup-input__container">
						<img className="popup-input__img" src={Icon2} alt="Icon" />
						<div className="popup-input__wrap">
							<label className="popup-input__border-text" htmlFor={bottomData[1].id}>
								{bottomData[1].inputBorderText}
							</label>
							<input
								className="popup-input__input"
								id={bottomData[1].id}
								type={bottomData[1].itemData.maxLength}
								placeholder={bottomData[1].placeHolder}
								maxLength={data.maxLength}
								name={'flyingTo'}
								readOnly={false}
								required={true}
							/>
						</div>
					</div>{' '}
				</div>
				<div className="dates__section popup__section">
					<h1 className="popup-dates__header">Travel Dates</h1>
					<div className="dates__input-container popup-input-container">
						{/* <PopupBottomInput
							inputId={bottomData[2].id}
							inputIcon={Icon3}
							borderText={bottomData[2].inputBorderText}
							data={bottomData[2].itemData}
							placeHolderText={bottomData[2].placeHolder}
							inputName={'from'}
							required={true}
						/> */}
						<div className="popup-input__container">
							<img className="popup-input__img" src={Icon3} alt="Icon" />
							<div className="popup-input__wrap">
								<label
									className="popup-input__border-text"
									htmlFor={bottomData[2].id}
								>
									{bottomData[2].inputBorderText}
								</label>
								<input
									className="popup-input__input"
									id={bottomData[2].id}
									type={bottomData[2].itemData.type}
									placeholder={bottomData[2].placeHolder}
									maxLength={data.maxLength}
									name={'from'}
									required={true}
								/>
							</div>
						</div>{' '}
						<div className="popup-input__container">
							<img className="popup-input__img" src={Icon3} alt="Icon" />
							<div className="popup-input__wrap">
								<label
									className="popup-input__border-text"
									htmlFor={bottomData[3].id}
								>
									{bottomData[3].inputBorderText}
								</label>
								<input
									className="popup-input__input"
									id={bottomData[3].id}
									type={bottomData[3].itemData.type}
									placeholder={bottomData[3].placeHolder}
									maxLength={10}
									name={'to'}
									required={true}
								/>
							</div>
						</div>{' '}
					</div>
				</div>
				<div className="contact-details-popup__section popup__section">
					<div className="contact-details__input-container popup-input-container">
						<div className="popup-input__container">
							<img className="popup-input__img" src={Icon4} alt="Icon" />
							<div className="popup-input__wrap">
								<label
									className="popup-input__border-text"
									htmlFor={bottomData[4].id}
								>
									{bottomData[4].inputBorderText}
								</label>
								<input
									className="popup-input__input"
									id={bottomData[4].id}
									type={bottomData[4].itemData.type}
									placeholder={bottomData[4].placeHolder}
									maxLength={data.maxLength}
									name={'name'}
									required={true}
								/>
							</div>
						</div>
						<div className="popup-input__container">
							<img className="popup-input__img" src={Icon5} alt="Icon" />
							<div className="popup-input__wrap">
								<label
									className="popup-input__border-text"
									htmlFor={bottomData[5].id}
								>
									{bottomData[5].inputBorderText}
								</label>
								<input
									className="popup-input__input"
									id={bottomData[5].id}
									type={bottomData[5].itemData.type}
									placeholder={bottomData[5].placeHolder}
									maxLength={data.maxLength}
									name={'phoneNo'}
									required={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<button className="popup-submit-btn btn" type="submit">
					Check Availability
				</button>
			</form>
		</div>
	);
};

export default Popup;
