import './Section6.css';

import TopicText from '../../TopicText/TopicText';
import DestinationPost from '../../DestinationPost/DestinationPost';

import BaliSnorkeling from '../../../img/Destinations/bali-snorkeling.jpg';
import BaliSpas from '../../../img/Destinations/bali-spas.jpeg';
import BaliHiking from '../../../img/Destinations/bali-hiking.jpeg';
import BaliTemple from '../../../img/Destinations/bali-temple.jpg';

const Section6 = () => {
	return (
		<div className="section-6">
			<TopicText
				title="Attractive Bali Destinations"
				paragraph="Bali's allure rests in the fact that you can do as little or as much as you wish. Bali entices visitors with an abundance of water sports, hiking trails, waterfalls, and ancient temples, in addition to its endless beaches and well-known nightlife. This is also a place recognised for its culture, arts, and shopping, with plenty of opportunities to buy local crafts.Make time for a little pampering among the many things to do in Bali – the spas here are incredible."
			/>
			<div className="home-common-wrap">
				<div className="section-6-posts-container">
					<div className="section-6-posts-row-container">
						<div className="section-6-post-container">
							<DestinationPost
								id="d-post-container-1"
								title="Snorkeling at Blue Lagoon"
								paragraph="Snorkeling in Blue Lagoon, Bali should be on your bucket list. Discover and experience numerous underwater wonderlands."
								img={BaliSnorkeling}
							/>
						</div>
						<div className="section-6-post-container">
							<DestinationPost
								id="d-post-container-2"
								title="Bali Spas"
								paragraph="The therapeutic, spiritual, and relaxing credentials of Bali spas are well-known throughout the world. There are plenty of places to relax and unwind."
								img={BaliSpas}
							/>
						</div>
					</div>
					<div className="section-6-posts-row-container">
						<div className="section-6-post-container">
							<DestinationPost
								id="d-post-container-3"
								title="Hiking Bali"
								paragraph="Whether you want to go through volcanic mountains, beautiful rice fields, or lush green rainforests and waterfalls, hiking in Bali allows you to enjoy, at your own pace, nature at its best."
								img={BaliHiking}
							/>
						</div>
						<div className="section-6-post-container">
							<DestinationPost
								id="d-post-container-4"
								title="Tanah Lot Temple"
								paragraph="Tanah Lot temple located 20 kilometers northwest of Kuta on a rocky outcropping amid roaring surf, is an ancient Hindu shrine. The beautiful landmark, one of seven sea temples dotting the Balinese coast is thought to date back to the 16th century."
								img={BaliTemple}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Section6;
