import React, { useState } from 'react';
import TopicText from '../../TopicText/TopicText';
import './GetQuoteSection2.css';
import PopupBottomInput from '../../Input/PopupBottomInput/PopupBottomInput';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

import Icon1 from '../../../img/icons/popup-icon-1.png';
import Icon2 from '../../../img/icons/popup-icon-2.png';
import Icon3 from '../../../img/icons/popup-icon-3.png';
import Icon4 from '../../../img/icons/popup-icon-4.png';
import Icon5 from '../../../img/icons/popup-icon-5.png';
import Icon6 from '../../../img/icons/popup-icon-6.png';
import Icon7 from '../../../img/icons/popup-icon-7.png';

const GetQuoteSection2 = () => {
	const bottomData = [
		{
			id: 'i1',
			icon: { Icon1 },
			inputBorderText: 'Flying From',
			itemData: '',
			placeHolder: 'Gold Coast Airport',
		},
		{
			id: 'i2',
			icon: { Icon2 },
			inputBorderText: 'Flying To',
			itemData: '',
			placeHolder: 'Adelaide Airport',
		},
		{
			id: 'i3',
			icon: { Icon3 },
			inputBorderText: 'From',
			itemData: { type: 'date', value: '' },
			placeHolder: '',
		},
		{
			id: 'i4',
			icon: { Icon3 },
			inputBorderText: 'To',
			itemData: { type: 'date', value: '' },
			placeHolder: '',
		},
		{
			id: 'i5',
			icon: { Icon4 },
			inputBorderText: '',
			itemData: '',
			placeHolder: 'Name',
		},
		{
			id: 'i6',
			icon: { Icon5 },
			inputBorderText: '',
			itemData: { type: 'tel', maxLength: 20 },
			placeHolder: 'Phone No.',
		},
		{
			id: 'i7',
			icon: { Icon5 },
			inputBorderText: '',
			itemData: { type: 'email' },
			placeHolder: 'Email',
		},
		{
			id: 'i8',
			icon: { Icon5 },
			inputBorderText: '',
			itemData: { type: 'text' },
			placeHolder: 'Subject',
		},
	];

	const [formdata, setFormdata] = useState({
		'trip-type': 'return',
		classes: 'economy',
		Adults: 0,
		Children: 0,
		Infants: 0,
		flyingFrom: bottomData[0].placeHolder,
		flyingTo: bottomData[1].placeHolder,
	});

	const onFormChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setFormdata((formdata) => ({ ...formdata, [name]: value }));
	};

	const submitEmail = (event) => {
		event.preventDefault();
		emailjs.send('service_ukz9c9m', 'template_s8pxaz6', formdata, '764tE797wwOEx9DU4')
		.then((result) => {
			Swal.fire({
				title: 'Success',
				text: ' Successfully Sent Your Request',
				icon: 'success',
				allowOutsideClick: false,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: 'OK',
				confirmButtonColor: '#E94E1B',
				cancelButtonText: 'Cancel',
				imageUrl: null,
				imageSize: null,
				timer: null,
				customClass: '',
				html: false,
				animation: true,
				allowEscapeKey: true,
				inputType: 'text',
				inputPlaceholder: '',
				inputValue: '',
				showLoaderOnConfirm: false	
			})
		}, (error) => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...!',
				text: error.text,
			})
		});
	}

	return (
		<div className="gq-sec-2">
			<TopicText
				title="Get A Quote For Your Ride"
				paragraph="Flight Master has the largest team of travel experts in the world, dedicated to helping you get the best value and greatest experiences out of any holiday. Start your travel enquiry now by filling out the form below, and one of our travel agents will get back to you. If you’re keen to secure the hottest deal right now, call 0431 133 327 for free advice from one of our expert agents"
			></TopicText>
			<div className="home-common-wrap">
				<form className="gq-form-container" onSubmit={submitEmail} onChange={onFormChange}>
					<div className="gq-section-2 gq-trip-type__section">
						<div className="trip-type__radio-btns">
							<div className="trip-type__radio-btn trip-type__radio-btn">
								<input
									type="radio"
									id="return-radio-btn"
									name="trip-type"
									value="return"
									radioGroup="trip-type"
									defaultChecked
									style={{accentColor:"rgb(233,78,27)"}}
								/>
								<label
									className="trip-type__radio-btn-label"
									htmlFor="return-radio-btn"
								>
									Return
								</label>
							</div>
							<div className="trip-type__radio-btn radio-btn-2">
								<input
									type="radio"
									id="oneway-radio-btn"
									name="trip-type"
									value="one-way"
									radioGroup="trip-type"
									style={{accentColor:"rgb(233,78,27)"}}
								/>
								<label
									className="trip-type__radio-btn-label"
									htmlFor="oneway-radio-btn"
								>
									OneWay
								</label>
							</div>
						</div>
					</div>
					<div className="gq-section-2 gq-ticket-class__section">
						<div>
							<h1 className="flight-class__header ticket__form-header">
								Ticket Class
							</h1>
							<select
								className="flight-classes__selector ticket-input-container"
								name="classes"
								id="flight-classes"
								defaultValue={'business'}
							>
								<option
									className="flight-classes__selector-option"
									value="business"
								>
									Business Class
								</option>
								<option className="flight-classes__selector-option" value="economy">
									Economy Class
								</option>
							</select>
						</div>
					</div>
					<div className="gq-section-2 gq-passengers__section">
						<h2 className="gq-passengers__header popup__form-header">Passengers</h2>
						<div className="passengers__input-container">
							<div className="gq-passenger-type__container">
								<h1 className="passenger-type__header">{'Adults'}</h1>
								<h3
									style={{ paddingBottom: '24px' }}
									className="passenger-age-group__sub-header"
								>
									{''}
								</h3>
								<input
									className="passenger-type__input"
									type="number"
									id="points"
									name="Adults"
									defaultValue={0}
								/>
							</div>
							<div className="gq-passenger-type__container">
								<h1 className="passenger-type__header">{'Children'}</h1>
								<h3 className="passenger-age-group__sub-header">{'2-11 years'}</h3>
								<input
									className="passenger-type__input"
									type="number"
									id="points"
									name="Children"
									defaultValue={0}
								/>
							</div>
							<div className="gq-passenger-type__container">
								<h1 className="passenger-type__header">{'Infants'}</h1>
								<h3 className="passenger-age-group__sub-header">
									{'under 2 years'}
								</h3>
								<input
									className="passenger-type__input"
									type="number"
									id="points"
									name="Infants"
									defaultValue={0}
								/>
							</div>
						</div>
					</div>
					{/*  */}
					<div className="destination__section popup__section bottom-input__container ">
						<div className="destination__input-container popup-input-container">
							<PopupBottomInput
								inputId={bottomData[0].id}
								inputIcon={Icon1}
								borderText={bottomData[0].inputBorderText}
								data={bottomData[0].itemData}
								placeHolderText={bottomData[0].placeHolder}
								inputName={'flyingFrom'}
								readonly={false}
								required={true}
							/>
							<PopupBottomInput
								inputId={bottomData[1].id}
								inputIcon={Icon2}
								borderText={bottomData[1].inputBorderText}
								data={bottomData[1].itemData}
								placeHolderText={bottomData[1].placeHolder}
								inputName={'flyingTo'}
								readonly={false}
								required={true}
							/>
						</div>
					</div>
					{/*  */}
					<div className="dates__section popup__section bottom-input__container">
						<h1 className="gq-dates__header popup__form-header">Travel Dates</h1>
						<div className="dates__input-container popup-input-container">
							<PopupBottomInput
								inputId={bottomData[2].id}
								inputIcon={Icon3}
								borderText={bottomData[2].inputBorderText}
								data={bottomData[2].itemData}
								placeHolderText={bottomData[2].placeHolder}
								inputName={'from'}
								required={true}
							/>
							<PopupBottomInput
								inputId={bottomData[3].id}
								inputIcon={Icon3}
								borderText={bottomData[3].inputBorderText}
								data={bottomData[3].itemData}
								placeHolderText={bottomData[3].placeHolder}
								inputName={'to'}
								required={true}
							/>
						</div>
					</div>
					<div className="contact-details-popup__section popup__section bottom-input__container ">
						<div className="contact-details__input-container popup-input-container">
							<PopupBottomInput
								inputId={bottomData[4].id}
								inputIcon={Icon4}
								borderText={bottomData[4].inputBorderText}
								data={bottomData[4].itemData}
								placeHolderText={bottomData[4].placeHolder}
								inputName={'name'}
								required={true}
							/>
							<PopupBottomInput
								inputId={bottomData[5].id}
								inputIcon={Icon5}
								borderText={bottomData[5].inputBorderText}
								data={bottomData[5].itemData}
								placeHolderText={bottomData[5].placeHolder}
								inputName={'phoneNo'}
								required={true}
							/>
						</div>
					</div>{' '}
					<div className="contact-details-popup__section popup__section bottom-input__container">
						<div className="contact-details__input-container popup-input-container">
							<PopupBottomInput
								inputId={bottomData[6].id}
								inputIcon={Icon6}
								borderText={bottomData[6].inputBorderText}
								data={bottomData[6].itemData}
								placeHolderText={bottomData[6].placeHolder}
								inputName={'email'}
								required={true}
							/>
							<PopupBottomInput
								inputId={bottomData[7].id}
								inputIcon={Icon7}
								borderText={bottomData[7].inputBorderText}
								data={bottomData[7].itemData}
								placeHolderText={bottomData[7].placeHolder}
								inputName={'subject'}
								required={true}
							/>
						</div>
					</div>
					<input
						type="text"
						className="gq-message__box"
						placeholder="Your Message"
						name="message"
						required
					/>
					<button type="submit" className="gq-form__button btn">
						Send
					</button>
					{/*  */}
				</form>
			</div>
		</div>
	);
};

export default GetQuoteSection2;
