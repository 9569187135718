import './Section3.css';
import Feature from '../../Feature/Feature';
import TopicText from '../../TopicText/TopicText';
import CustomerServiceIcon from '../../../img/feature-icons/customer-service.png'
import HandIcon from '../../../img/feature-icons/hand.png'
import PeaceofMindIcon from '../../../img/feature-icons/peace-of-mind.png'
import ExperienceIcon from '../../../img/feature-icons/experience.png'

const Section3 = () => {
	return (
		<div className="sec-3">
			<TopicText
				title="Discover New Destinations Around The Globe With Our Flight Deals."
				paragraph="Flight Master is committed to giving you the best possible experience, including amazing value packages and inspirational travel articles to help you plan, discover, and dream. Explore Australia, New Zealand, Asia, and the Pacific through our national and international network. We’re known for our economical options and low-budget specialties. Our customers benefit from the finest airline deals on the planet as well as award-winning customer service from our friendly staff."
			/>
			<div className="home-common-wrap">
				<div className="features-container item-container">
					<Feature
						id="feature-1"
						icon={CustomerServiceIcon}
						title="Service"
						description="Travel made easy"
						/>
					<Feature
						id="feature-2"
						icon={HandIcon}
						title="Value"
						description="Irressistible deals for every traveller"
					/>
					<Feature
						id="feature-3"
						icon={PeaceofMindIcon}
						title="Peace of mind"
						description="Here for you, always"
						/>
					<Feature
						id="feature-4"
						icon={ExperienceIcon}
						title="Expertise"
						description="Experience our experience"
					/>
				</div>
			</div>
		</div>
	);
};

export default Section3;
