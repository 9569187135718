import React from 'react';
import './ContactUsSection3.css';

const ContactUsSection3 = () => {
	return (
		<div className="cu-sec-3">
			<div className="home-common-wrap">
				<div className="cu-sec-3__map-container">
					<iframe
						title="Our Location"
						className="cu-sec-3__map"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.677817350746!2d144.94775911535638!3d-37.82101484223144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d68a0be4da5%3A0xa3d1588d8be7f5ba!2sTower%20Five!5e0!3m2!1sen!2slk!4v1653940867272!5m2!1sen!2slk"
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default ContactUsSection3;