import './Home.css';

// image
import Section1 from '../../components/Sections/Section1/Section1';
import Section2 from '../../components/Sections/Section2/Section2';
import Section3 from '../../components/Sections/Section3/Section3';
import Section4 from '../../components/Sections/Section4/Section4';
import Section5 from '../../components/Sections/Section5/Section5';
import Section6 from '../../components/Sections/Section6/Section6';
import Section7 from '../../components/Sections/Section7/Section7';
import Section8 from '../../components/Sections/Section8/Section8';
import Footer from '../../common/Footer/Footer';

const Home = () => {
	return (
		<div className="home-main">
			<div className="first-section">
				<Section1/>
			</div>
			<div className="second-section section">
				<Section2/>
			</div>
			<div className="third-section section">
				<Section3/>
			</div>
            <div className="fourth-section section">
                <Section4 />
            </div>
			<div className="fifth-section section">
				<Section5/>
			</div>
			<div className="sixth-section section">
				<Section6/>
			</div>
 			<div className="seventh-section section">
				<Section7/>
			</div>
			<div className="eightth-section about-section section" >
				<Section8 />
			</div>
			<Footer />
		</div>
	);
};

export default Home;