/* eslint-disable jsx-a11y/alt-text */
import SlidePic from "../../img/Globe-1.png";
import "./Slide.css";

const Slide = (	props ) => {
	return (
		<div className="slide-wrap">
			<div className="left-side flex-side  __  text-container">
				<div className="slogan-item welcome-text">Welcome to</div>
				<div className="slogan-item brand-name">Flight Master</div>
				<div className="slogan-item text-paragraph">
					{props.paragraph}
				</div>
				<button className="slogan-item btn">OUR SERVICES</button>
			</div>
			<div className="right-side flex-side  __  slide-img-container">
				<img src={SlidePic} className="slide-pic" />
			</div>
		</div>
		// <section className="main-slide-container">
		//   <div className="columns is-mobile pt-6">
		//     <div className="column is-2"></div>
		//     <div className="column is-5 main-column">
		//       <div className="slogan-item welcome-text">Welcome to</div>
		//       <div className="slogan-item brand-name">Flight Master</div>
		//       <div className="slogan-item text-paragraph">
		//         Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum
		//         dolor sit amet consectetur adipisicing elit.
		//       </div>
		//       <button className="slogan-item btn">OUR SERVICES</button>
		//     </div>
		//     <div className="column is-5">
		//       <img src={SlidePic} className="slide-pic-new" />
		//     </div>
		// <svg
		//   viewBox="0 0 1440 113"
		//   fill="#f9f6f6"
		//   xmlns="http://www.w3.org/2000/svg"
		//   id="wave"
		// >
		//   <path
		//     d="M360 0C720 0 720 94.5 1080 94.5C1260 94.5 1350 70.875 1440 47.25V126H0V47.25C90 23.625 180 0 360 0Z"
		//     fill="#f9f6f6"
		//   />
		// </svg>
		//   </div>
		// </section>
	);
};

export default Slide;
