import React from 'react';
import ReactDOM from 'react-dom';
import Popup from './Popup/Popup';
import './popupModal.css'

const PopupModal = ({ open, children, onClose ,flightData }) => {
	if (!open) return null;

	return ReactDOM.createPortal(
		<>
			<div className='modal-overlay' ></div>
			<div className='modal-container'>
				<Popup openPopup={open} closePopup={onClose} data={flightData}></Popup>
			</div>
		</>,
		document.getElementById('portal')
	);
};

export default PopupModal;