import React from 'react';
import CommonPageHeader from '../../../common/CommonPageHeader/CommonPageHeader';
import './GetQuoteSection1.css'


const GetQuoteSection1 = () => {
	return (
		<div className="gq-sec-1">
			<CommonPageHeader HeaderText='Get a quote' ></CommonPageHeader>
		</div>
	);
};

export default GetQuoteSection1;
