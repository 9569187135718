import React from 'react'
import './PopupBottomInput.css';

const PopupBottomInput = ({inputId,inputIcon,borderText,data,placeHolderText,inputName,readonly,required}) => {
  return (
    <div className='popup-bottom-input__container'>
        <img className='popup-bottom-input__img' src={inputIcon} alt="Icon" />
        <div className="popup-bottom-input__wrap">
          <label className='popup-bottom-input__border-text' htmlFor={inputId}>{borderText}</label>
          <input className='popup-bottom-input__input' id={inputId} type={data.type} placeholder={placeHolderText} maxLength={data.maxLength} name={inputName} readOnly={readonly || false} required={required || false}/>
        </div>
    </div>
  )
}

export default PopupBottomInput