import './Feature.css';
// import WhiteBall from '../../img/white-ball-1.png'
// import FeatureIcon1 from '../../img/FeatureIcons/feature-icon-1.png'


const Feature = (props) => {
	return (
		<div id={props.id} className="feature-container">
			<div className="feature-icon-container">
                <img src={props.icon} alt="Features" />
            </div>
			<h4 className="feature-heading">{props.title}</h4>
			<p className="feature-paragraph">{props.description}</p>
			<a className="feature-link" href='/'>Read More</a>
		</div>
	);
};

export default Feature;
