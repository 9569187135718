import './Section1.css';
import Slide from '../../Slide/Slide';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const Section1 = () => {
	return (
		<div className="sec-1">
			<div className="welcome-banner">
				<div className="home-common-wrap">
					<Swiper
						// install Swiper modules
						modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
						spaceBetween={0}
						slidesPerView={1}
						navigation
						autoplay={{ delay: 3000 }}
					>
						<SwiperSlide>
							<div className="slider-wrap">
								<div className="slides-container">
									<Slide paragraph="Flight Master Australia is one of the largest travel retailers in Australia and New Zealand.which is connecting with  over 300 direct airlines around the world.we are here to make your travel easy" />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="slider-wrap">
								<div className="slides-container">
									<Slide paragraph="Flight Master Price Beat Guarantee is part of our continual commitment to providing you with the most competitive rates." />
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="slider-wrap">
								<div className="slides-container">
									<Slide
										paragraph="Our customers benefit from the finest airline deals on the planet as well as award-winning customer service from our friendly staff. With Flight Master, we’re confident your trip will be one to remember."
									/>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="slider-wrap">
								<div className="slides-container">
								<Slide
										paragraph="Flight Master is one of the  Australia's leading travel  agency, providing low-cost domestic and international flights and best connection to suit your travel needs. "
									/>
								</div>
							</div>
						</SwiperSlide>{' '}
					</Swiper>{' '}
				</div>
				{/* <div className="slider-wrap">
					<div className="slide-btn">
						<i class="fa-solid fa-angle-left"></i>
					</div>
					<div className="slides-container">
						<Slide />
					</div>
					<div className="slide-btn">
						<i class="fa-solid fa-angle-right"></i>
					</div>
				</div> */}
				{/* <div className="slide-bottom__bar"></div> */}
				<svg
					viewBox="0 0 1440 113"
					fill="#f9f6f6"
					xmlns="http://www.w3.org/2000/svg"
					id="wave"
					className="sec-1-slide-wave"
				>
					<path
						className="sec-1-slide-wave-path"
						d="M360 0C720 0 720 94.5 1080 94.5C1260 94.5 1350 70.875 1440 47.25V126H0V47.25C90 23.625 180 0 360 0Z"
						fill="#f9f9f9"
					/>
				</svg>
			</div>
		</div>
	);
};

export default Section1;
