import React from 'react';
import './CommonPageHeader.css';

const CommonPageHeader = (props) => {
	return (
		<div className="page-header-section" >
			<div className="page-header-section-overlay">
				<div className="page-header-text">{props.HeaderText}</div>
			</div>
		</div>
	);
};

export default CommonPageHeader;
