import React,{useState} from 'react';
import './ContactForm.css';

import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const ContactForm = () => {
	const [formdata, setFormdata] = useState({});
	const onFormChange = (event)=>{
		const name = event.target.name;
    	const value = event.target.value;
    	setFormdata(formdata => ({...formdata, [name]: value}))
	}

	const submitEmail=(event)=>{
		event.preventDefault();
		emailjs.send('service_ukz9c9m', 'template_ln74svm', formdata, '764tE797wwOEx9DU4').then(
			(result) => {
				Swal.fire({
				title: 'Success',
				text: ' Successfully Sent Your Enquiry',
				icon: 'success',
				allowOutsideClick: false,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: 'OK',
				confirmButtonColor: '#E94E1B',
				cancelButtonText: 'Cancel',
				imageUrl: null,
				imageSize: null,
				timer: null,
				customClass: '',
				html: false,
				animation: true,
				allowEscapeKey: true,
				inputType: 'text',
				inputPlaceholder: '',
				inputValue: '',
				showLoaderOnConfirm: false	
			})
			},
			(error) => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...!',
					text: error.text,
				});
			}
		);
	}
	return (
		<div className="contact-form__container">
			<form className="contact-form" onChange={onFormChange} onSubmit={submitEmail}>
				<input className="contact-form__input" type="text" placeholder="Name" name='name' required={true}/>
				<input className="contact-form__input" type="email" placeholder="Email" name='email' required={true}/>
				<input className="contact-form__input" type="tel" placeholder="Contact Number" name='contactNumber' required={true}/>
				<input className="contact-form__input" type="text" placeholder="Subject" name='subject' required={true}/>
				<input
					className="contact-form__input contact-form-message__input"
					type="text"
					placeholder="Message"
					name='message'
					required={true}
				/>
				<button type='submit' className="contact-form__btn btn">Send</button>
			</form>
			<div className="contact-form__social-icons-container">
				<div className="contact-form__social-icon-container">
					<i className="fa-brands fa-whatsapp contact-form__social-icon"></i>
				</div>
				<div className="contact-form__social-icon-container">
					<i className="fa-brands fa-facebook-f contact-form__social-icon"></i>				
				</div>
				<div className="contact-form__social-icon-container">
					<i className="fa-brands fa-instagram contact-form__social-icon"></i>
				</div>
				<div className="contact-form__social-icon-container">
					<i className="fa-regular fa-envelope contact-form__social-icon"></i>
				</div>
			</div>
		</div>
	);
};

export default ContactForm;
