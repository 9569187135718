import { useState } from 'react';
import PopupModal from '../PopupModal/PopupModal';
import './Post.css';

const Post = (props) => {
	const postData = props;

	const [isOpen, setIsOpen] = useState(false);
	return (
		<div id={props.id} className="post-container">
			<div className="post-img-container">
				<img className="post-img" src={props.img} alt="Travels" />
			</div>
			<div className="post-text-container">
				<h4 className="post-heading">{props.title}</h4>
				<p className="post-para">{props.description}</p>
				<h1 className="post-price">{props.price}</h1>
				<div className="post-btn-container">
					<button onClick={() => setIsOpen(true)} className="post-btn">
						Check Availability
					</button>
					<PopupModal flightData={postData} open={isOpen} onClose={() => setIsOpen(false)}>
						Button123
					</PopupModal>
				</div>
			</div>
		</div>
	);
};

export default Post;
