import './App.css';
import Footer from './common/Footer/Footer';
import Navbar from './common/Navbar/Navbar';
import Home from './screens/Home/Home';
// import ComingSoon from './screens/ComingSoon/ComingSoon';


import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import GetQuote from './screens/GetQuote/GetQuote';
import ContactUs from './screens/ContactUs/ContactUs';
import PageNotFound from './screens/PageNotFound/PageNotFound';

function App() {
  return (
    <Router>      
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/get-quote' element={<GetQuote />}></Route>
        <Route path='/contact-us' element={<ContactUs />}></Route>
        <Route path='/*' element={<PageNotFound />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
