import './Section2.css';

import Post from '../../Post/Post';
import TopicText from '../../TopicText/TopicText';

import Glacier from '../../../img/Flight/Glacier.jpg';
import SeaWaves from '../../../img/Flight/sea-waves.jpg';
import InletMountain from '../../../img/Flight/inlet-mountain.jpg';
import BridalFall from '../../../img/Flight/bridal-fall.jpg';

const Section2 = () => {
	return (
		<div className="sec-2">
			<TopicText
				title="Still Unsure? Here’s Some of Our most Popular Travel Destinations"
				paragraph="All of your favorite destinations in Australia and New Zealand can now be reached via domestic flights. What’s more, we also offer the prospect of traveling internationally. Flight Master allows you to fly wherever you choose around the world, whether it's for family holidays, adrenaline-pumping adventure getaways, top gourmet escapes, elegant health retreats, or everything in between. Allow us to assist you in planning your next trip..."
			/>
			<div className="home-common-wrap">
				<div className="posts-container item-container">
					<Post
						id="post-1"
						title="Sydney to Cairns"
						price="$75"
						description="Take advantage of our economical flight offers to Cairns; easy on your wallet while giving you the trip of a lifetime."
						img={Glacier}
					/>
					<Post
						id="post-2"
						title="Sydney to Gold Coast"
						price="$875"
						description="With our best value flight tickets, you can fly to Gold Coast without worrying so much about finances."
						img={SeaWaves}
					/>
					<Post
						id="post-3"
						title="Sydney to Hamilton Island"
						price="$115"
						description="Enjoy a getaway to Hamilton Island with the best value for money with our best value flight offer."
						img={InletMountain}
					/>
					<Post
						id="post-4"
						title="Sydney to Auckland"
						price="$85"
						description="Getting to Auckland has never been easier: All you have to do is look at our top flight bargains! "
						img={BridalFall}
					/>
				</div>
			</div>
		</div>
	);
};

export default Section2;
