import React from 'react';
import './GetQuote.css';

import GetQuoteSection1 from '../../components/GetQuoteSections/GetQuoteSection1/GetQuoteSection1';
import GetQuoteSection2 from '../../components/GetQuoteSections/GetQuoteSection2/GetQuoteSection2';
import GetQuoteSection3 from '../../components/GetQuoteSections/GetQuoteSection3/GetQuoteSection3';
import GetQuoteSection4 from '../../components/GetQuoteSections/GetQuoteSection4/GetQuoteSection4';
import Footer from '../../common/Footer/Footer';

const GetQuote = () => {
	return (
		<div className="get-quote-main">
			<div className="gq-first-section">
				<GetQuoteSection1 />
			</div>
			<div className="gq-second-section gq-section">
				<GetQuoteSection2></GetQuoteSection2>
			</div>
			<div className="gq-third-section gq-section">
				<GetQuoteSection3></GetQuoteSection3>
			</div>
			<div className="gq-fourht-section gq-section">
				<GetQuoteSection4></GetQuoteSection4>
			</div>
			<Footer />
		</div>
	);
};

export default GetQuote;
