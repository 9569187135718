import React from 'react';
import './ContactUsSection4.css';
import HorizontalPostSection from '../../HorizontalPostSection/HorizontalPostSection';
import TravelImage from '../../../img/Horizontal post/Travel-Image.png';

const ContactUsSection4 = () => {
	return (
		<div className="cu-sec-4">
			<HorizontalPostSection
				image={TravelImage}
				tagline="Get a Quick Quote"
				subTagline="& Fly to your dream destination"
				text="Flight Master has the largest team of travel experts in the world, dedicated to helping you get the best value and greatest experiences out of any holiday. Start your travel enquiry now by filling out the form below, and one of our travel agents will get back to you. If you are keen to secure the hottest deal right now, call 0431 133 327 for free advice from one of our expert agents."
				buttonText="See More"
				rightImage={false}
				backgroundColorWhite={false}
			></HorizontalPostSection>
		</div>
	);
};

export default ContactUsSection4;
