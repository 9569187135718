import './Footer.css';
import Logo from '../../img/white-version-1.png';
import Flight from '../../img/flight-icon.png';
import BulletedList, { BulletedListItem } from '@bbc/psammead-bulleted-list';

const Footer = () => {
	return (
		<footer className="d-footer">
			<div className="plane">
				{/* <img src={FooterReactangle} alt="Footer" className="footer-reactangle" /> */}
				<div className="clipppp"></div>
				<img src={Flight} alt="Flight" className="footer-plane-icon" />
			</div>
			<div className="home-common-wrap">
				<div className="column-sections">
					<div className="about-section column-section">
						<div className="flight-master-logo">
							<img src={Logo} alt="Flight Master" />
						</div>
						{/* <div className="footer-about-header footer-section-heading">About Us</div> */}
						<div className="about-paragraph">
							<BulletedList bulletPointColour="#e94e1b" bulletPointShape="round">
								<BulletedListItem>
									Flight Master Australia is one of Australia's and New Zealand's
									major travel retailers. We take care all the hard work so they
									may save money and vacation more. We are confident in our
									abilities with our cheerful personnel, we provide our clients
									with the best airline deals in the globe as well as
									award-winning customer service.
								</BulletedListItem>
							</BulletedList>
						</div>
					</div>
					<div className="sitemap-section column-section">
						<h1 className="footer-section-heading">Site Map</h1>
						<ul className="sitemap-menu footer-menu">
							<li>Home</li>
							<li>About Us</li>
							<li>Our Services</li>
							<li>Our Specialities</li>
							<li>Latest News</li>
							<li>Contact</li>
						</ul>
					</div>
					<div className="location-section column-section">
						<h1 className="footer-section-heading">Location Categories</h1>
						<ul className="sitemap-menu footer-menu">
							<li>Theme Park</li>
							<li>Places {'&'} Shopping</li>
							<li>Historical</li>
							<li>Island</li>
							<li>Iconic</li>
							<li>Variety</li>
						</ul>
					</div>
					<div className="contact-section column-section">
						<h1 className="footer-section-heading">Contact Us</h1>
						<ul className="sitemap-menu footer-menu">
							<li>
								<i class="fa-solid fa-phone"></i>
								Mobile :- <a href="tel:0431133327">0431 133 327</a>
							</li>
							<li>
								- Melbourne - <a href="tel:0387653535">03 8765 3535</a>
							</li>
							<li>
								- Sydney - <a href="tel:0287783131">02 8778 3131</a>
							</li>
							<li>
								<i className="fa-solid fa-location-dot"></i>
								FlightMaster Australia,
								<br />
								- Level 24 Collins Square Tower Five,
								<br />
								- 272 Collins Street,
								<br />
								- Melbourne VIC <br />- 3008
							</li>
							<li>
								<a href="mailto:anne@flightmaster.com.au">
									<i className="fa-solid fa-envelope"></i>
									hello@flightmaster.com.au
								</a>
							</li>
							<li>
								<iframe
									title="Our Location"
									className="map"
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.677817350746!2d144.94775911535638!3d-37.82101484223144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d68a0be4da5%3A0xa3d1588d8be7f5ba!2sTower%20Five!5e0!3m2!1sen!2slk!4v1653940867272!5m2!1sen!2slk"
									allowFullScreen
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
								></iframe>
							</li>
						</ul>
					</div>
				</div>
				<div className="bottom-sections">
					<div className="copy-right bottom-section">
						<span>
							{'©'} Copyright 2022 Flight Master. All Rights Reserved. | Privacy {'&'}{' '}
							Terms
						</span>
					</div>
					<div className="social-links bottom-section">
						<a className="social-link" href="/">
							<i className="fa-brands fa-facebook"></i>
						</a>
						<a className="social-link" href="/">
							<i className="fa-brands fa-instagram"></i>
						</a>
						<a className="social-link" href="/">
							<i className="fa-solid fa-envelope"></i>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
