import React from 'react';
import CommonContactBox from '../../../common/CommonContactBox/CommonContactBox';

const Section8 = () => {
	return (
		<>
			<CommonContactBox></CommonContactBox>
		</>
	);
};

export default Section8;
