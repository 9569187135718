import React from 'react';
import CommonPageHeader from '../../../common/CommonPageHeader/CommonPageHeader';
import './ContactUsSection1.css';

const ContactUsSection1 = () => {
	return <div className='cu-sec-1'>
        <CommonPageHeader HeaderText='Contact Us'></CommonPageHeader>
    </div>;
};

export default ContactUsSection1;
