import React from 'react';
import './HorizontalPostSection.css';
const HorizontalPostSection = (props) => {
	let postContainerStyles;
	if (props.rightImage === true) {
		postContainerStyles = { flexDirection: 'row-reverse' };
	}

	let sloganContainerStyles;
	if (props.rightImage === true) {
		sloganContainerStyles = { marginLeft: '0', marginRight: '2rem' };
	}

	let sloganTextColor;
	if (props.backgroundColorWhite) {
		sloganTextColor = { color: '#000' };
    } else {
		sloganTextColor = { color: '#fff' };
	}

	return (
		<div className="home-common-wrap">
			<div className="horizontal-post__container" style={postContainerStyles}>
				<img className="hp-img" src={props.image} alt="Your travel partner" />
				<div className="hp-slogan-container" style={sloganContainerStyles}>
					<h1 style={sloganTextColor} className="hp-tagline">
						{props.tagline}
					</h1>
					<h1 style={sloganTextColor} className="hp-tagline hp-sub-tagline">
						{props.subTagline}
					</h1>
					<p style={sloganTextColor} className="hp-slogan-text">
						{props.text}
					</p>
					<button className="hp-section-btn">{props.buttonText}</button>
				</div>
			</div>
		</div>
	);
};

export default HorizontalPostSection;
