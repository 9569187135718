import React from 'react'
import Footer from '../../common/Footer/Footer'
import './PageNotFound.css'

const PageNotFound = () => {
  return (
    <div>
      <h1>PageNot Found</h1>
      <h1>PageNot Found</h1>
      <h1>PageNot Found</h1>
      <h1>PageNot Found</h1>
      <h1>PageNot Found</h1>
      <h1>PageNot Found</h1>
      <h1>PageNot Found</h1>
      <h1>PageNot Found</h1>
      <Footer />
    </div>
  )
}

export default PageNotFound