import './TopicText.css';

const TopicText = (props) => {
	return (
		<div className="home-common-wrap">
			<div className="topic-text__container">
				<div className="topic-text__section-heading">{props.title}</div>
				<div className="topic-text__heading-icon"></div>
				<p className="topic-text__paragraph">{props.paragraph}</p>
			</div>
		</div>
	);
};

export default TopicText;
